import React from 'react'

export const Test = () => (
  <>
    <div className="hero">
      <h1>Test</h1>
    </div>
    <section className="hero is-fullheight-with-navbar"></section>
  </>
)
